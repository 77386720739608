import badge from '../images/UnityBadge.png';

export default function Education() {
	return (
		<div className="education-container">
			<div className="education-content">
				<div className="container-left w-screen-sm md:w-max flex flex-col items-center">
					<div className="w-full md:max-w-screen-md text-center my-4">
						<h2 className="text-xl font-bold">
							Advanced Diploma in IT (Multimedia Software Development)
						</h2>
						<p className="text-lg">MQF Level 4</p>
						<p className="text-lg">2018 - 2020</p>
					</div>
					<div className="w-full md:max-w-screen-md text-center my-4">
						<h2 className="text-xl font-bold">
							Bachelor of Science (Honours) in Multimedia Software Development
						</h2>
						<p className="text-lg">MQF Level 6</p>
						<p className="text-lg">2020 - 2023</p>
					</div>
					<div className="w-full md:max-w-screen-md text-center my-4">
						<div className="flex flex-col md:flex-row items-center justify-center md:ml-28">
							<div className="text-center md:text-left">
								<h2 className="text-xl font-bold">
									Unity Certified Associate: Programmer
								</h2>
								<p className="text-lg text-center">2024</p>
							</div>
							<div className="w-1/4 md:w-24 ml-0 md:ml-4">
								<a
									href="https://cp.certmetrics.com/unity/en/public/verify/credential/fcea93683df44903b0bb3b0efdc73a40"
									target="_blank"
									rel="noopener noreferrer" // Add rel for security
								>
									<img
										src={badge}
										alt="Unity Certified Associate: Programmer Badge"
										className="mx-auto md:mx-0"
									/>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
