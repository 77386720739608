import React from 'react';
import './App.css';
import NavBar from './components/NavBar';
import HeaderFunction from './components/HeaderFunction';
import Profile from './components/Profile';
import Education from './components/Education';
import WorkExperience from './components/WorkExperience';
import Projects from './components/ProjectCarousel';
import Technologies from './components/Technologies';
import EmailForm from './components/EmailForm';
import GitHub from './images/github.svg';
import LinkedIn from './images/linkedin.svg';
import TopDownSurvivor from './images/TopDownSurvivor.png';
import KnightsAndVikings from './images/KnightsAndVikings.png';
import TerrainGenerator from './images/TerrainGenerator.png';
import IndoorSimulation from './images/IndoorSimulation.png';
import FModDemo from './images/FModDemo.png';
import SpellSurge from './images/SpellSurge_Icon.png';

const projects = [
	{
		title: 'TopDown Survivor',
		image: TopDownSurvivor,
		link: 'https://www.youtube.com/watch?v=P8b-jFs3tXg',
		description:
			'A game made using Unity which I worked on as a solo project. The game is a top down shooter with a focus on survival.',
	},
	{
		title: 'Knights & Vikings',
		image: KnightsAndVikings,
		link: 'https://www.youtube.com/watch?v=CjDT-aUH3y4',
		description:
			'A game made using Unity which features online connectivity. The game is a 2D PvP platformer where players compete to get the most points.',
	},
	{
		title: 'Thesis Simulation',
		image: IndoorSimulation,
		link: 'https://www.youtube.com/watch?v=K-2IAdgajRQ',
		description:
			'My thesis project which is a simulation made in Unity using the A* Pathfinding algorithm and the ML-Agents toolkit. The goal was to simulate a crowd reacting to an accident',
	},
	{
		title: 'FMod Demo',
		image: FModDemo,
		link: 'https://www.youtube.com/watch?v=5hQ8ImFaOj4',
		description:
			'A demo combining FMOD and TopDown Survivor to add randomized sound to a game.',
	},
	{
		title: 'Terrain Generator',
		image: TerrainGenerator,
		link: 'https://www.youtube.com/watch?v=bpwgRaaBucw',
		description:
			'A video showcasing procedurely generated maps in Unity using various algorithms, this demo shows how a maze, terrain and track have been generated using only code.',
	},
	{
		title: 'Spell Surge',
		image: SpellSurge,
		size: 'w-1/2 mx-auto',
		description:
			'A personal project made using Roblox Studio which features a spell casting system and a variety of spells to use.',
		badge: true,
	},
];

function Footer() {
	return (
		<footer className="bg-gray-700 text-white w-100 py-4">
			<div className="container mx-auto flex justify-center items-center">
				<a
					href="https://github.com/Nate-Lia"
					className="text-gray-400 hover:text-white fill-current mx-2"
					target="_blank"
					rel="noopener noreferrer"
				>
					<img
						style={{ width: '48px', height: '48px' }}
						src={GitHub}
						alt="Github"
					></img>
				</a>
				<a
					href="https://www.linkedin.com/in/nathan-lia-b321a822b/"
					className="text-gray-400 hover:text-white mx-2"
					target="_blank"
					rel="noopener noreferrer"
				>
					<img
						style={{ width: '48px', height: '48px' }}
						src={LinkedIn}
						alt="LinkedIn"
					></img>
				</a>
			</div>
		</footer>
	);
}
export default function App() {
	return (
		<div className="overflow-x-visible">
			<NavBar className="z-auto" />
			<HeaderFunction id="about" hidden="hidden" />
			<Profile id="about" />
			<div className="flex flex-col md:flex-row justify-between">
				<div className="flex-grow w-full md:w-5/12 mr-12">
					<HeaderFunction title="Education" width="full" id="education" />
					<div className="flex justify-center items-center">
						<Education id="education" />
					</div>
				</div>
				<div className="flex-grow w-full md:w-5/12">
					<HeaderFunction
						title="Work Experience"
						width="full"
						id="work-experience"
					/>
					<div className="flex justify-center items-center">
						<WorkExperience id="work-experience" />
					</div>
				</div>
			</div>
			<HeaderFunction title="Projects" width="full" id="projects" />
			<Projects projects={projects} />
			<br />
			<HeaderFunction title="Familiar Technologies" width="full" id="tech" />
			<Technologies />
			<div className="max-w-md mx-auto">
				<HeaderFunction title="Contact Me!" width="full" id="contact" />
			</div>
			<EmailForm />
			<div className="text-sm flex md:flex-col text-center items-center justify-center md:text-left mt-16">
				<p>Website built using React & Tailwind </p>
				<p>
					Icons provided by{' '}
					<a style={{ color: 'blue' }} href="https://icons8.com/">
						icons8.com
					</a>
				</p>
			</div>

			<Footer />
		</div>
	);
}
