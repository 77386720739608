import ProfilePicture from './ProfilePicture';
import HeaderFunction from './HeaderFunction';
import picture from '../images/profilePicture.jpeg';

export default function Profile() {
	return (
		<div
			className={
				'profile-container w-100 overflow-hidden md:shadow-lg mt-8 py-4 mx-auto flex flex-wrap md:flex-nowrap items-center justify-center md:justify-between'
			}
		>
			<div
				className={'profile-left w-max flex flex-col md:flex-row items-center'}
			>
				<div
					className={
						'profile-picture overflow-hidden w-64 h-auto items-center justify-center mb-4 md:ml-4'
					}
				>
					<ProfilePicture
						src={picture}
						alt={'LinkedIn Profile Picture'}
					></ProfilePicture>
				</div>
				<div className="profile-title items-center md:items-start justify-center md:justify-start md:ml-5">
					<h1 className="font-sans text-center text-4xl font-bold md:text-4xl lobster-two-bold">
						Nathan Lia
					</h1>
					<p className="md:text-xl josefin-sans-regular ml-5 text-center md:ml-5 mb-5 px-3">
						Passionate about Game Dev & Fullstack
					</p>
				</div>
			</div>
			<div className="profile-right md:pl-5 md:w-1/2">
				<HeaderFunction title="About Me" width="full" id="education" />
				<p className="text-lg text-center pr-5">
					Hey there! I'm Nate, a 21-year-old software enthusiast skilled in
					languages like C#, JavaScript, and Python. I've worked with tools such
					as the Adobe Suite and Unity, and explored 3D modeling in Maya &
					Blender, as well as game development with Lua and Roblox Studio.
					Collaboration and motivation are at the core of what I bring to any
					team. Outside of work, I enjoy everything from video games to board
					games, and I love hosting and creating stories for Dungeons & Dragons.
					I'm also a huge music enthusiast and enjoy playing guitar, piano,
					bass, and drums.
				</p>
			</div>
		</div>
	);
}
