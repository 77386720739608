// EmailForm.js
import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const EmailForm = () => {
	const [formData, setFormData] = useState({
		name: '',
		email: '',
		subject: '',
		message: '',
	});
	const [toastMessage, setToastMessage] = useState('');
	const [showToast, setShowToast] = useState(false);
	const [toastVisible, setToastVisible] = useState(false);
	const [toastImage, setToastImage] = useState('');

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		// Send email using EmailJS
		emailjs
			.send(
				'service_kpwwnkd',
				'template_7e7znvj',
				formData,
				'DBkuHSQOvQowexN2O'
			)
			.then(
				(response) => {
					console.log('SUCCESS!', response.status, response.text);
					setToastMessage('Email sent successfully!'); // Success message
					setToastImage('https://img.icons8.com/fluency/24/checked.png'); // Success image
					showToastWithAnimation(); // Show the toast notification
				},
				(err) => {
					console.error('FAILED...', err);
					setToastMessage('Failed to send email. Please try again later.'); // Error message
					setToastImage('https://img.icons8.com/color/24/close-window.png'); // Error image
					showToastWithAnimation(); // Show the toast notification
				}
			);

		// Clear the form
		setFormData({ name: '', email: '', subject: '', message: '' });
	};

	const showToastWithAnimation = () => {
		setShowToast(true); // Show the toast notification
		setToastVisible(true); // Start the slide-up animation

		// Hide it after 3 seconds
		setTimeout(() => {
			setToastVisible(false); // Start sliding down
			setTimeout(() => setShowToast(false), 300); // Hide after sliding down
		}, 3000);
	};

	return (
		<div className="max-w-md mx-auto relative">
			{showToast && (
				<div
					className={`fixed bottom-5 left-1/2 transform -translate-x-1/2 transition-all duration-300 ${
						toastVisible
							? 'translate-y-0 opacity-100'
							: 'translate-y-20 opacity-0'
					} bg-gray-600 text-white px-4 py-2 rounded shadow-md flex`}
				>
					<img
						className="mr-2"
						width="24"
						height="24"
						src={toastImage} // Dynamically set the image source
						alt="Notification"
					/>
					{toastMessage}
				</div>
			)}

			<form
				className="p-6 bg-white rounded-lg shadow-md"
				onSubmit={handleSubmit}
			>
				<div className="mb-4">
					<label className="block text-gray-700">Name:</label>
					<input
						type="text"
						name="name"
						value={formData.name}
						onChange={handleChange}
						required
						className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
						placeholder="Name"
					/>
				</div>
				<div className="mb-4">
					<label className="block text-gray-700">Email:</label>
					<input
						type="email"
						name="email"
						value={formData.email}
						onChange={handleChange}
						required
						className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
						placeholder="Email"
					/>
				</div>
				<div className="mb-4">
					<label className="block text-gray-700">Subject:</label>
					<input
						type="text"
						name="subject"
						value={formData.subject}
						onChange={handleChange}
						required
						className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
						placeholder="Subject"
					/>
				</div>
				<div className="mb-4">
					<label className="block text-gray-700">Message:</label>
					<textarea
						name="message"
						value={formData.message}
						onChange={handleChange}
						required
						className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
						placeholder="Message"
					/>
				</div>
				<button
					className="w-full bg-gray-600 text-white p-2 rounded hover:bg-gray-700 transition"
					type="submit"
				>
					Send Email
				</button>
			</form>
		</div>
	);
};

export default EmailForm;
