const Card = ({ title, link, image, size, description, badge }) => {
	return (
		<div className="w-full flex flex-col justify-center items-center">
			<h1 className="text-center text-2xl font-bold mb-4">{title}</h1>{' '}
			<a
				className="md:w-1/4 m-4"
				href={link}
				target="_blank"
				rel="noopener noreferrer"
			>
				<img className={size} src={image} alt="Project" />
			</a>
			<p className="md:w-1/3 mt-4 text-center">{description}</p>
			{badge && (
				<span class="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10 my-4 badge-animation">
					⚠️ Work In Progress ⚠️
				</span>
			)}
		</div>
	);
};

export default Card;
