export default function HeaderFunction({ title, width, id, hidden }) {
	const headerStyle = `bg-gray-600 text-white md:w-${width} py-4 my-4 ${hidden}`;

	return (
		<header id={id} className={headerStyle}>
			<div className="container mx-auto flex justify-center items-center my-4">
				<h1 className="text-white text-center text-4xl">{title}</h1>
			</div>
		</header>
	);
}
