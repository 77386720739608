import React from 'react';
import Slider from 'react-slick';
import Card from './Card';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ProjectCarousel = ({ projects }) => {
	const settings = {
		dots: true,
		infinite: true,
		autoplay: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	return (
		<div className="slider-container w-full shadow-lg pt-8">
			<Slider {...settings}>
				{projects.map((project, index) => (
					<div key={index}>
						<Card
							title={project.title}
							link={project.link}
							image={project.image}
							size={project.size}
							description={project.description}
							badge={project.badge}
						/>
					</div>
				))}
			</Slider>
		</div>
	);
};

export default ProjectCarousel;
