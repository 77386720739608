export default function WorkExperience() {
	return (
		<div className="education-container">
			<div className="education-content">
				<div className="container-left w-screen-sm md:w-full flex flex-grow flex-wrap items-center">
					<div className="w-full md:w-1/2 my-4 text-center">
						<h2 className="text-xl font-bold">Junior Backend Developer</h2>
						<p className="text-lg">Xtended Gaming</p>
						<p className="text-lg">2023 - Present</p>
					</div>
					<div className="w-full md:w-1/2 text-center">
						<h2 className="text-xl font-bold">Software Developer</h2>
						<p className="text-lg text-center">
							AIS Technology - Apprenticeship
						</p>
						<p className="text-lg text-center">2022 - 2023</p>
					</div>
					<div className="w-full md:w-1/2 text-center">
						<h2 className="text-xl font-bold">Web Developer</h2>
						<p className="text-lg text-center">
							Malta Laboratories Network - Summer Job
						</p>
						<p className="text-lg text-center">Jun 2021 - Aug 2021</p>
					</div>
					<div className="w-full md:w-1/2 text-center">
						<h2 className="text-xl font-bold">Data Entry</h2>
						<p className="text-lg text-center">
							Arts Council Malta - Summer Job
						</p>
						<p className="text-lg text-center">Jun 2019 - Aug 2021</p>
					</div>
				</div>
			</div>
		</div>
	);
}
