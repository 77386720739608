import azure from '../images/azure.svg';

export default function Technologies() {
	return (
		<div className="w-full overflow-hidden md:shadow-lg md:mt-8">
			<div className="px-2 py-2">
				<div className="grid grid-cols-2 gap-4 md:grid-cols-5 md:ml-12">
					<TechnologyItem
						imgSrc="https://img.icons8.com/ios-filled/250/c-sharp-logo.png"
						alt="C# Icon"
						text=".NET Framework"
					/>
					<TechnologyItem
						imgSrc="https://img.icons8.com/ios-filled/150/adobe-logo.png"
						alt="Adobe Suite Icon"
						text="Adobe Suite"
					/>
					<TechnologyItem
						imgSrc="https://img.icons8.com/ios-filled/150/unity.png"
						alt="Unity Icon"
						text="Unity"
					/>
					<TechnologyItem
						imgSrc="https://img.icons8.com/ios-filled/150/python.png"
						alt="Python Icon"
						text="Python"
					/>
					<TechnologyItem
						imgSrc="https://img.icons8.com/ios-filled/150/javascript.png"
						alt="JavaScript Icon"
						text="JavaScript"
					/>
					<TechnologyItem
						imgSrc="https://img.icons8.com/ios/150/java-coffee-cup-logo--v1.png"
						alt="Java Icon"
						text="Java"
					/>
					<TechnologyItem
						imgSrc="https://img.icons8.com/ios-filled/150/react-native--v1.png"
						alt="React Icon"
						text="React"
					/>
					<TechnologyItem
						imgSrc="https://img.icons8.com/windows/128/vuejs.png"
						alt="Vue Icon"
						text="VueJS"
					/>
					<TechnologyItem
						imgSrc="https://img.icons8.com/ios-filled/150/ableton.png"
						alt="Ableton Icon"
						text="Ableton"
					/>
					<TechnologyItem
						imgSrc="https://img.icons8.com/external-tal-revivo-light-tal-revivo/150/external-autodesk-an-american-multinational-software-corporation-for-the-architecture-and-other-engineering-services-logo-light-tal-revivo.png"
						alt="Autodesk Icon"
						text="Maya 3D Design"
					/>
					<TechnologyItem
						imgSrc="https://img.icons8.com/ios-filled/50/roblox-studio.png"
						alt="roblox-studio"
						text="Roblox Studio"
					/>
					<TechnologyItem imgSrc={azure} alt="Azure" text="Microsoft Azure" />
				</div>
			</div>
		</div>
	);
}

function TechnologyItem({ imgSrc, alt, text }) {
	return (
		<div className="border-b-4 border-t-4 border-gray-500 border-solid rounded-xl py-1 text-md font-semibold mr-2 mb-2 flex flex-col w-40 md:w-52 text-center items-center hover:bg-gray-400 hover:border-gray-400">
			<img src={imgSrc} alt={alt} className="w-16 h-16" />
			<span>{text}</span>
		</div>
	);
}
