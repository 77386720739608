import React, { useState, useEffect } from 'react';

const NavBar = () => {
	const [isOpen, setIsOpen] = useState(false);

	const toggleNavbar = () => {
		setIsOpen(!isOpen);
	};

	useEffect(() => {
		const smoothScroll = (target) => {
			const targetElement = document.querySelector(target);
			if (targetElement) {
				window.scrollTo({
					top: targetElement.offsetTop,
					behavior: 'smooth',
				});
			}
		};

		const handleNavClick = (event) => {
			event.preventDefault();
			const target = event.target.getAttribute('href');
			smoothScroll(target);
		};

		const navLinks = document.querySelectorAll('nav a');
		navLinks.forEach((link) => {
			link.addEventListener('click', handleNavClick);
		});

		return () => {
			navLinks.forEach((link) => {
				link.removeEventListener('click', handleNavClick);
			});
		};
	}, []);

	return (
		<nav className="z-50 sticky top-0 left-0 w-full bg-gray-700">
			<div className="flex justify-between items-center py-4 w-full">
				<div className="ml-4">
					<h1 className="text-xl font-bold text-white">
						<i>Nathan Lia</i>
					</h1>
				</div>
				{/* Desktop menu */}
				<div className="hidden md:flex space-x-4 mr-4">
					<a href="#about" className="hover:text-gray-300 text-white px-3 py-2">
						About
					</a>
					<a
						href="#education"
						className="hover:text-gray-300 text-white px-3 py-2"
					>
						Education
					</a>
					<a
						href="#work-experience"
						className="hover:text-gray-300 text-white px-3 py-2"
					>
						Work Experience
					</a>
					<a
						href="#projects"
						className="hover:text-gray-300 text-white px-3 py-2"
					>
						Projects
					</a>
					<a href="#tech" className="hover:text-gray-300 text-white px-3 py-2">
						Technologies
					</a>
					<a
						href="#contact"
						className="hover:text-gray-300 text-white px-3 py-2"
					>
						Contact Me
					</a>
				</div>
				{/* Mobile menu toggle */}
				<div className="flex md:hidden mr-4">
					<button
						onClick={toggleNavbar}
						className="flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
					>
						<svg
							className={`${isOpen ? 'hidden' : 'block'} h-6 w-6`}
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
							aria-hidden="true"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth={2}
								d="M4 6h16M4 12h16m-7 6h7"
							/>
						</svg>
						<svg
							className={`${isOpen ? 'block' : 'hidden'} h-6 w-6`}
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
							aria-hidden="true"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth={2}
								d="M6 18L18 6M6 6l12 12"
							/>
						</svg>
					</button>
				</div>
			</div>
			{/* Mobile menu */}
			<div
				className={`${
					isOpen ? 'block' : 'hidden'
				} md:hidden absolute top-full left-0 w-full bg-gray-700 px-2 pt-2 pb-3 space-y-1 sm:px-3`}
			>
				<a href="#about" className="block px-3 py-2 text-white">
					About
				</a>
				<a href="#education" className="block px-3 py-2 text-white">
					Education
				</a>
				<a href="#work-experience" className="block px-3 py-2 text-white">
					Work Experience
				</a>
				<a href="#projects" className="block px-3 py-2 text-white">
					Projects
				</a>
				<a href="#tech" className="block px-3 py-2 text-white">
					Technologies
				</a>
				<a href="#contact" className="block px-3 py-2 text-white">
					Contact Me
				</a>
			</div>
		</nav>
	);
};

export default NavBar;
